import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Cosmos Meta',
  defaultTitle: 'Cosmos Meta',
  description: 'The bridge between currency and the future aiming to encode real-world assets into the crypto market',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Cosmos Meta',
    site: '@Cosmos Meta',
  },
  openGraph: {
    title: 'Cosmos Meta',
    description: 'The bridge between currency and the future aiming to encode real-world assets into the crypto market',
    images: [{ url: 'https://market-v2.cmometa.com//bgBanner.jpg' }],
  },
}
