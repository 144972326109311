import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g>
        <path d="M24 19c0 1.654-1.346 3-3 3v1a1 1 0 1 1-2 0v-1h-.268a3.01 3.01 0 0 1-2.598-1.499 1 1 0 0 1 1.731-1.002c.179.31.511.501.867.501H21c.551 0 1-.448 1-1a.77.77 0 0 0-.644-.761l-3.041-.506A2.763 2.763 0 0 1 16 15c0-1.654 1.346-3 3-3v-1a1 1 0 1 1 2 0v1h.268a3.01 3.01 0 0 1 2.598 1.499 1 1 0 0 1-1.731 1.002 1.003 1.003 0 0 0-.867-.501H19c-.551 0-1 .448-1 1a.77.77 0 0 0 .644.761l3.041.506A2.763 2.763 0 0 1 24 19Zm-9.597 2.503a2.988 2.988 0 0 1-.302-2.277c.145-.54.429-1.018.824-1.394A4.766 4.766 0 0 1 14 15a5.01 5.01 0 0 1 4-4.899V7c0-2.757-2.243-5-5-5h-.171A3.006 3.006 0 0 0 10 0H8a3.006 3.006 0 0 0-2.829 2H5C2.243 2 0 4.243 0 7v12c0 2.757 2.243 5 5 5h8a4.96 4.96 0 0 0 2.867-.916 4.974 4.974 0 0 1-1.464-1.581Zm-4.676-7.296-2.179 2.179c-.409.409-.946.613-1.483.613s-1.074-.204-1.483-.613l-1.288-1.289a.999.999 0 1 1 1.414-1.414l1.288 1.289c.033.033.105.033.139 0l2.179-2.179a.999.999 0 1 1 1.414 1.414h-.001Zm0-6-2.179 2.179c-.409.409-.946.613-1.483.613s-1.074-.204-1.483-.613L3.294 9.097a.999.999 0 1 1 1.414-1.414l1.288 1.289c.033.033.105.033.139 0l2.179-2.179a.999.999 0 1 1 1.414 1.414h-.001Z" />
      </g>
    </Svg>
  );
};

export default Icon;
